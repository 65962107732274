.allocate-buttons {
  padding: 15px 0;

  .ant-col {
    padding-right: 16px;

    .grey-btn {
      background: $grey;
      color: #7f8385;
      padding: 7px 12px;
      font-size: 13px;
      background: rgba(185, 187, 189, 0.22);
      border-radius: 6px;
      height: 34px;
      padding-right: 16px;
      max-width: 150px;

      .btn_wrapper {
        margin-right: 7px;
      }

      &:hover {
        background: $grey-hover;
        color: #53595c;
      }
    }

    @media (max-width: 1366px) {
      padding-right: 6px;

      .grey-btn {
        padding: 6px 7px;
      }
    }
  }
}
.adjust {
  padding-right: 16px;
}
.allocate-board {
  .anticon {
    font-size: 14px !important;
  }
  .anticon + span {
    font-size: 13px !important;
  }
}

.service-time-prev-form {
  .ant-form-item-explain-error {
    position: absolute;
    bottom: -20px;
  }
  .line_dropdown {
    .ant-select-selector {
      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        padding: 7px 0 0 0;
      }
    }
  }

  .ant-form-item {
    margin-bottom: 0;
    padding-left: 0 !important;
    padding-bottom: 15px;
    padding-right: 0;

    .ant-col {
      .time {
        padding-bottom: 16px;
      }
      .ant-form-item {
        padding-bottom: 15px;
      }
      .ant-row-center {
        padding-bottom: 16px;
      }
    }
  }
}

// .ant-modal-wrap,
// .ant-modal-mask {
//     z-index: 9999;
// }

.ant-modal-wrap.white-modal.home_modal {
  z-index: 1002;
}

.allocation_snackbar {
  z-index: 999 !important;
}

.reorder-row-dragging {
  z-index: 9999;
}

