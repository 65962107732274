.report_section {
    width: 100%;
    background-color: #f5f5f5;
    padding-top: 64px;
    min-height: 100vh;
    .ant-card {
      margin: 16px;
    }
    .ant-card-body {
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  
    .report-button-card {
      margin: 16px 0;
      padding: 0;
      width: 100%;
      :hover {
        .card-title {
          color: #6bb870 !important;
        }
      }
      .card {
        margin: 0;
        height: 346px;
        .card-div {
          padding: 38px 0 16px 0;
        }
        .card-title {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          text-align: center;
          letter-spacing: 0.003em;
          color: #53595c;
          width: 100%;
          white-space: initial;
        }
        .card-paragraph {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          text-align: center;
          letter-spacing: 0.003em;
          color: #7f8385;
          width: 100%;
          white-space: initial;
        }
      }
    }
  }
  
  .productivity-report-daterange {
    .ant-picker {
      padding: 4px 30px;
      margin-bottom: 29px;
      display: flex;
    }
  }
  
  .report-date {
    .ant-form-item-control-input-content {
      background: rgba(185, 187, 189, 0.22);
      border-radius: 6px;
      padding: 6px 10px 7px 12px;
      .formControl {
        border-bottom: 0;
      }
    }
  }
  
  .custom_loader {
    position: relative;
    min-height: 68px;

    .loaderImage {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ant-spin-container {
      opacity: 1;

      &:after {
        display: none;
      }
    }

    .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
      top: 0%;
      left: 38%;
      margin: -6px 0 0 0;
      font-size: 80px;
      color: #6BB870;
    }
  }

  .popup_text_loader {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #7F8385;
    text-align: center;
    margin-top: 29px;
  }