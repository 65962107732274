.allocation_wrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.allocation_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 76px;
  border-bottom: 1px solid #E4E5E7;
  padding: 15px 25px 15px 40px;
  margin-bottom: 16px;

  h2 {
    margin-bottom: 0;
    font-weight: 500;
    color: #16181D;
    font-size: 20px;
    line-height: 28px;
  }

  button {
    background-color: #16181D;
    border-radius: 44px;
    padding: 8px 20px 8px 16px;
    display: flex;
    align-items: center;

    span {
      font-weight: 500;
      color: #fff;
      font-size: 14px;
      line-height: 20px;
      margin-left: 8px;
    }

    &:hover {
      background-color: #1A1C42;
    }
  }
}

.allocation_heading {
  border: 1px solid #F2F3F7;
  background-color: #FBFBFC;
  padding: 10px 14px;
  color: #505560;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  border-radius: 8px;
  margin-left: 8px;
  display: none;

  &.center {
    text-align: center;
  }
}

.psWrapper {
  .allocation_heading {
    margin: 0 16px 0 8px;
    width: calc(100% - 26px);
  }
}

.basket_wrapper {
  border: 1px solid #F2F3F7;
  border-radius: 12px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}




.basket_allocate_wrapper {
  position: absolute;
  bottom: 0;
  z-index: 9;
  left: 0px;
  padding: 8px;
  background-color: #fff;
  width: 100%;
  border-radius: 0 0 12px 12px;
}

.allocation_btn_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FBFBFC;    
    padding: 4px 8px;
    border-radius: 8px;
    height: 44px;
    position: relative;

    .basket_length {
      margin-right: auto;
    }

    &:hover {
        .allocation_btn {
          background-color: #0B9F57;
          width: 205px;
          border-radius: 44px;

          span {
            visibility: visible;
            width: auto;
            opacity: 1;
          }
        }
    }

    .allocation_btn {
      width: 36px;
      height: 36px;
      position: absolute;
      margin: auto;
      padding: 0;
      border-radius: 44px;
      background-color: #16181D;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      transition: all .4s ease-in-out;

      .ant-image {
        margin: 0 6px;
      }
    
      span {
        visibility: hidden;
        opacity: 0;
        width: 0;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        transition: width,opacity 0.75s ease-in-out;
      }
    }
}

.all_header {
  border-bottom: 1px solid #F2F3F7;
  padding: 16px 20px 12px;
  background: #FBFBFC;
  border-radius: 12px 12px 0 0;
}

.basket_credit_room {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  flex-wrap: wrap;
}

.room_time {
  height: 16px;
  color: #505560;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  width: 100%;
}

.allocation_btn {
  padding: 0;
}

.bottom_btn_changed {
  background-color: #16181D;
  height: 64px;
  border-radius: 12px;
  position: absolute;
  bottom: 12px;
  left: 40px;
  width: calc(100% - 80px);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}

.bottom_right {
  display: flex;
  align-items: center;

  .text_btn_allocation {
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    padding: 8px 20px 8px 16px;
    background: transparent;
    border-radius: 44px;
    display: flex;
    align-items: center;

    .ant-image {
      margin-right: 8px;
    }

    &:hover {
      background-color: rgba(255,255,255,.2);
    }
  }

  .green_btn_allocation {
    background-color: #0B9F57;
    padding: 8px 16px;
    border-radius: 44px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-left: 4px;

    &:hover {
      background-color: #018545;
    }
  }
}

.allocated_length {
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  padding-left: 4px;
}


.room_card_top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;

  .room_t_status {
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 8px;
  }

  .room_t_cleaning_pref {
    background-color: rgba(22,24,29,.1);
    border-radius: 13px;
    color: #FFFFFF;
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    display: flex;
    align-items: center;
    padding: 2px 8px 2px 6px;
  }

  .room_t_cleaning {
    margin-right: 2px;
  }
  
  .room_ct_right {
    text-align: right;
  }

  .room_t_type {
    position: relative;
    display: inline-flex;

    // &:after {
    //   background: linear-gradient(8.28deg, rgba(55, 52, 52, 0.1) -29.38%, rgba(0, 0, 0, 0) 63.28%);
    //   width: 86px;
    //   height: 34px;
    //   position: absolute;
    //   right: -8px;
    //   top: -7px;
    //   border-radius: 0 6px 0 0;
    // }

    .selected_room_check {
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: -3px;
      visibility: hidden;
      opacity: 0;
      width: 0;
      transition: .3s;
    }

    .ant-checkbox-inner {
      background-color: #fff;
      border: 0;
      border-radius: 2px;

      &:after {
        border: 2px solid #16181d;
        border-top: 0;
        border-left: 0;
      }
    }
  }

  .type_text {
    color: #F7F8F9;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: right;
    transition: .35s;
  }

  .room_number {
    color: #FFFFFF;
    font-size: 22px;
    font-weight: 500;
    line-height: 28px;
  }
}

.room_card_btm {
  position: relative;
  display: flex;
  justify-content: space-between;

  .room_status {
    width: 100%;
    background-color: rgba(255,255,255,.1);
    padding: 2px 8px;
    border-radius: 44px;
  }

  .room_detail_btn {
    transition: .35s;
    background-color: rgba(255,255,255,.1);
    padding: 2px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0;
    height: 20px;
    visibility: hidden;
    opacity: 0;

    img {
      display: block;
    }

    button {
      padding: 0;
      background: transparent;
    }
  }
}

.room_card_wrapper {
  width: 100%;
  &.allocation_card {
    .room_selected {
      .room_card_top .room_t_type .selected_room_check {
        width: 18px;
        opacity: 1;
        visibility: visible;
      }

      
    }

    .room_selected,
    &:hover {
      .room_card_btm {
        .room_detail_btn {
          margin-left: 4px;
          width: 20px;
          opacity: 1;
          visibility: visible;
        }
      }
      .selected_room_check {
        width: 18px;
        opacity: 1;
        visibility: visible;
      }
  
      .type_text {
        filter: blur(3px);
        -webkit-filter: blur(3px);
      }
      .room_t_type {
        &:after {
          content: '';
        }
      }
    }
    
  }
}

.ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking {
  background-color: transparent !important;
  opacity: 1 !important;
}

.ps__thumb-y {
  background-color: rgba(185, 187, 189, 0.5);
}

.ps__rail-y {
  width: 6px;
  opacity: 1;
}

.ps__rail-y:hover > .ps__thumb-y, .ps__rail-y:focus > .ps__thumb-y, .ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: rgba(185, 187, 189, 0.5);
  width: 6px;
}