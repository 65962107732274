.mb-0 {
    margin-bottom: 0 !important;
}

.green_circle_btn {
    background: $green !important;
    border: 0 !important;
}
.blue_circle_btn {
    background: #5E95E7 !important;
    border: 0 !important;
}
.no_border_btn {
    background: white !important;
    border: 0 !important;
    color: black !important;
    padding: 20px !important;
    font-size: xx-large !important;
   // font-weight: 700 !important;
    margin: -10px 0px !important;
    top: 0 !important;
}
.disabled_btn{
    background: rgba(133, 120, 215, 0.14) !important;
    border: 0 !important;
    color: #8578D7 !important;
    // padding: 20px !important;
    font-size: 12px !important;
    text-align: center;
    border-radius: 53px !important;
   // font-weight: 700 !important;
    // margin: -10px 0px !important;
    // top: 0 !important;
}

.ant-select-selection-item-content {
    text-transform: capitalize;
}

.custom_dropdown {
    &.ant-select-multiple {
        .ant-select-selector {
            padding-right: 30px !important;
            height: 34px;
            box-shadow: none !important;
            z-index: 9;
            cursor: pointer !important;

            .ant-select-selection-search {
                margin-inline-start: 0;
            }
        }

        .ant-select-selection-overflow {
            flex-wrap: nowrap;
            overflow: hidden;
        }

        .ant-select-selection-item {
            background: #fff;
            border: 0;
            border-radius: 4px;
        }
    }
    .ant-select-selector {
        background-color: rgba(185, 187, 189, 0.22) !important;
        border-radius: 8px !important;
        border: 0 !important;
        padding: 1px 12px !important;
        overflow: hidden;
        height: 34px !important;

        .ant-select-selection-placeholder {
            color: #7F8385;
        }

        ::-webkit-scrollbar-track {
            background-color: #f0f0f1 !important;
        }
    }
    .ant-select-arrow {
        color: #1B1C1D;
        z-index: 1;
    }
    .ant-avatar-image {
        margin-right: 5px;
    }
}

.line_dropdown {
    &.ant-select-multiple {
        .ant-select-selector {
            padding-right: 30px !important;
            height: 34px;
            box-shadow: none !important;
            padding-top: 5px !important;

            .ant-select-selection-search {
                margin-inline-start: 0;
            }
        }

        .ant-select-selection-overflow {
            flex-wrap: nowrap;
            overflow: hidden;
        }

        .ant-select-selection-item {
            background: #fff;
            border: 0;
            border-radius: 4px;
        }
    }
    .ant-select-selector {
        background-color: transparent !important;
        border-radius: 0 !important;
        border: 0 !important;
        border-bottom: 1px solid #DBDDDF !important;
        padding: 0 !important;
        overflow: hidden;
        min-height: 43px !important;
        z-index: 9;
        box-shadow: none !important;

        &:focus {
            box-shadow: none !important;
        }

        .ant-select-selection-placeholder {
            color: #B9BBBD;
            left: 0 !important;
            padding: 0 35px 0 0;
            
            span {
                text-transform: capitalize;
            }
        }
    }
    .ant-select-arrow {
        color: #1B1C1D;
        z-index: 1;
    }
    // .ant-avatar-image {
    //     margin-right: 5px;
    // }
}

.ant-picker-focused {
    box-shadow: none !important;
}

.p-0 {
    padding: 0 !important;
}
.p-10 {
    padding: 10px !important;
}

.m-0 {
    margin: 0 !important;
}

.font-18 {
    font-size: 18px !important;
    font-weight: 500 !important;
    color: #1B1C1D !important;
}

.back_btn {
    display: flex;
    align-items: center;

    .anticon {
        + span {
            font-weight: 700;
            font-size: 22px !important;
            line-height: 31px;
        }
    }
}

.line_qty {
    border: 0;
    border-bottom: 1px solid #DBDDDF;
    border-radius: 0;
    width: 100%;
    box-shadow: none;
    margin-bottom: 14px;
    margin-top: 15px;
    
    input {
        text-align: center;
        color: #1B1C1D;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        padding: 20px 0 22px;
        height: auto;
    }
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
    left: 0;
    right: 0;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    font-weight: 400 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, 
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, 
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    border-radius: 3px !important;
}

.ant-select-selection-item {
    text-transform: capitalize;
}

.ant-layout {
    background-color: transparent;
}

.option-label-item {
    text-overflow: ellipsis;
    overflow: hidden;
}

.no_access_wrapper {
    width: 100%;
    height: 100vh;
    padding: 40px;

    .no_access_screen {
        background: #FBFBFC;
        border-radius: 12px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .user_logo {
            margin-bottom: 32px;
        }

        h2 {
            color: #16181D;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
        }

        p {
            width: 500px;
            color: #505560;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 48px;
        }

        .app_logos {
            display: flex;
            align-items: center;

            a {
                margin: 0 12px;
            }
        }
    }
}

.error_email {
    max-width: 300px;

    .ant-message-error {
        text-align: left;
    }
}