.note-drawer {
  .ant-drawer-header {
    flex-direction: row-reverse;
    .right-arrow {
      background: none;
      color: black;
      padding: 8px;
    }
  }
}

.checklist_card {
  .drag-visible-icon {
    padding-left: 0 !important;

    .drag-inner-header {
      width: 0;
      position: absolute;
      top: 10px;

      .drag-icon {
        width: 0;
        margin-left: -3px;

        .anticon-holder {
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}

.checklist-row-dragging {
  .drag-visible-icon {
    padding-left: 0 !important;
    background-color: chartreuse;

    .drag-inner-header {
      width: 0;
      position: absolute;
      top: 18px;
      left: -10px;

      .drag-icon {
        width: 0;
        margin-left: -3px;

        .anticon-holder {
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  .drag-name {
    background-color: blueviolet;
    padding-top: 16px;
  }
  .drag-details {
    width: 210px;
    padding: 8px;
  }
  .drag-start-time {
    width: 130px;
    padding: 8px;
    padding-top: 16px;
  }
  .drag-due-time {
    width: 130px;
    padding: 8px;
    padding-top: 16px;
  }
  .drag-assigned {
    width: 120px;
    padding: 8px;
    padding-top: 4px;

    .ant-avatar-group {
      .ant-avatar {
        color: #53595c;
        width: 28px;
        border: 1px solid #53595c;
        height: 28px;
        font-size: 12px !important;
        background: #fff !important;
        font-weight: 400;
      }
    }
    .ant-avatar-group {
      .ant-avatar:not(:first-child) {
        margin-left: -4px;
      }
    }
  }
  .drag-action {
    width: 50px;
    padding-left: 16px;
    padding-top: 8px;
  }
  .drag-status {
    width: 116px;
    padding: 8px;
    padding-top: 16px;
  }
  .drag-completetime {
    width: 130px;
    padding: 8px;
    padding-top: 16px;
  }

  // @media screen and (max-width: 800px) {
  //   .drag-both-name {
  //     padding-top: 16px;
  //     padding-left: 38px;

  //     .drag-div-name{
  //         // width: 60vw;
  //       }
  //    }
  //   .drag-individual-name {
  //     padding-top: 16px;
  //       padding-left: 38px;

  //       .drag-div-name {
  //         width: 23vw;
  //     }
  //   }
  //   .drag-common-name {
  //     padding-top: 16px;
  //     padding-left: 38px;

  //     .drag-div-name {
  //     width: 11vw;
  //   }
  // }
  // }
  @media screen and (min-width: 801px) and (max-width: 1024px) {
    .drag-both-name {
      padding-top: 16px;
      padding-left: 38px;

      .drag-div-name {
        width: 15vw;
      }
    }
    .drag-individual-name {
      padding-top: 16px;
      padding-left: 38px;

      .drag-div-name {
        width: 31vw;
      }
    }
    .drag-common-name {
      padding-top: 16px;
      padding-left: 38px;

      .drag-div-name {
        width: 15vw;
      }
    }
  }
  @media screen and (min-width: 1025px) and (max-width: 1152px) {
    .drag-both-name {
      padding-top: 16px;
      padding-left: 38px;

      .drag-div-name {
        width: 30vw;
      }
    }
    .drag-individual-name {
      padding-top: 16px;
      padding-left: 38px;

      .drag-div-name {
        width: 44vw;
      }
    }
    .drag-common-name {
      padding-top: 16px;
      padding-left: 38px;

      .drag-div-name {
        width: 20vw;
      }
    }
  }
  @media screen and (min-width: 1153px) and (max-width: 1200px) {
    .drag-both-name {
      padding-top: 16px;
      padding-left: 38px;

      .drag-div-name {
        width: 35vw;
      }
    }
    .drag-individual-name {
      padding-top: 16px;
      padding-left: 38px;

      .drag-div-name {
        width: 48vw;
      }
    }
    .drag-common-name {
      padding-top: 16px;
      padding-left: 38px;

      .drag-div-name {
        width: 25vw;
      }
    }
  }
  @media screen and (min-width: 1201px) and (max-width: 1280px) {
    .drag-both-name {
      padding-top: 16px;
      padding-left: 38px;

      .drag-div-name {
        width: 38vw;
      }
    }
    .drag-individual-name {
      padding-top: 16px;
      padding-left: 38px;

      .drag-div-name {
        width: 50vw;
      }
    }
    .drag-common-name {
      padding-top: 16px;
      padding-left: 38px;

      .drag-div-name {
        width: 29vw;
      }
    }
  }
  @media screen and (min-width: 1281px) and (max-width: 1360px) {
    .drag-both-name {
      padding-top: 16px;
      padding-left: 38px;

      .drag-div-name {
        width: 42vw;
      }
    }
    .drag-individual-name {
      padding-top: 16px;
      padding-left: 38px;

      .drag-div-name {
        width: 53vw;
      }
    }
    .drag-common-name {
      padding-top: 16px;
      padding-left: 38px;

      .drag-div-name {
        width: 33vw;
      }
    }
  }
  @media screen and (min-width: 1361px) and (max-width: 1440px) {
    .drag-both-name {
      padding-top: 16px;
      padding-left: 38px;

      .drag-div-name {
        width: 46vw;
      }
    }
    .drag-individual-name {
      padding-top: 16px;
      padding-left: 38px;

      .drag-div-name {
        width: 55vw;
      }
    }
    .drag-common-name {
      padding-top: 16px;
      padding-left: 38px;

      .drag-div-name {
        width: 37vw;
      }
    }
  }
  @media screen and (min-width: 1441px) and (max-width: 1580px) {
    .drag-both-name {
      padding-top: 16px;
      padding-left: 38px;

      .drag-div-name {
        width: 50vw;
      }
    }
    .drag-individual-name {
      padding-top: 16px;
      padding-left: 38px;

      .drag-div-name {
        width: 59vw;
      }
    }
    .drag-common-name {
      padding-top: 16px;
      padding-left: 38px;

      .drag-div-name {
        width: 41vw;
      }
    }
  }
  @media screen and (min-width: 1581px) and (max-width: 1680px) {
    .drag-both-name {
      padding-top: 16px;
      padding-left: 38px;

      .drag-div-name {
        width: 53vw;
      }
    }
    .drag-individual-name {
      padding-top: 16px;
      padding-left: 38px;

      .drag-div-name {
        width: 62vw;
      }
    }
    .drag-common-name {
      padding-top: 16px;
      padding-left: 38px;

      .drag-div-name {
        width: 46vw;
      }
    }
  }
  @media screen and (min-width: 1681px) and (max-width: 1920px) {
    .drag-both-name {
      padding-top: 16px;
      padding-left: 38px;

      .drag-div-name {
        width: 58vw;
      }
    }
    .drag-individual-name {
      padding-top: 16px;
      padding-left: 38px;

      .drag-div-name {
        width: 66vw;
      }
    }
    .drag-common-name {
      padding-top: 16px;
      padding-left: 38px;

      .drag-div-name {
        width: 51vw;
      }
    }
  }
}
