.dashboard_section {
  width: 100%;
  height: 100vh;

  .section_header {
    position: relative;
    z-index: 9;
    height: auto;
    margin: 40px 0 10px 0;
    line-height: 32px;
    font-size: 28px;

    .display-hotelname {
      justify-content: space-between;
      width: 100%;
      padding-right: 24px;
      font-size: 16px;
      color: #53595c;
      line-height: 19px;
      font-style: normal;
      font-weight: 500;
      padding-left: 40px;
      .ant-typography {
        margin: 0;
      }
    }
  }

  .content {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(0deg, rgba(185, 187, 189, 0.14), rgba(185, 187, 189, 0.14)), #ffffff;

    .contentWrapper1 {
      width: 100%;
      padding: 16px 20px 16px 40px;
      min-height: 100vh;
      
      @media (max-width: 1499px) {
        padding: 12px 10px 16px 12px;
      }

      .guestRequest {
        background-color: #fff;
        .ant-card-meta-detail {
          .ant-card-meta-title {
            color: #D95B33;
          }
        }
      }
      .backOfficeRequest {
        background-color: #fff;
        .ant-card-meta-detail {
          .ant-card-meta-title {
            color: #782EC2;
          }
        }
      }
      .overdueTasks {
        background-color: #fff;
        .ant-card-meta-detail {
          .ant-card-meta-title {
            color: #E04369;
          }
        }
      }
      .openTasks {
        background-color: #fff;
        .ant-card-meta-detail {
          .ant-card-meta-title {
            color: #2E8DC2;
          }
        }
      }
      .ant-card-meta {
        margin: 0;
        
        .ant-card-meta-detail {
          .ant-card-meta-title {
            font-size: 32px;
            font-weight: 500;
            line-height: 1;
            margin-bottom: 8px;
          }
          .ant-card-meta-description {
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0.003em;
            color: #16181D;

            @media (max-width: 1599px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .dashboardTabs {
    width: 100%;

    &.newTabs .ant-table-container table > .ant-table-tbody > tr td {
      padding: 18px 12px 18px;
    }

    .ant-tabs-nav {
      font-size: 14px;
      width: 378px;
    }

    .ant-tabs-tab {
      &:hover {
        color: #6bb870 !important;
      }
      .ant-tabs-tab-btn {
        &:active,
        &:focus {
          color: #6bb870 !important;
        }
      }
    }
    .ant-tabs-tab-active {
      .ant-tabs-tab-btn,
      &:active {
        // font-size: 14px !important;
        color: #16181D !important;
        text-align: center;
        letter-spacing: 0.003em;
      }
    }
    .ant-tabs-ink-bar {
      height: 4px !important;
      background: #6bb870 !important;
      border-radius: 5px 5px 0px 0px !important;
    }

    .ant-tabs-tab {
      margin: 0;
      padding: 8px 16px;
      color: #b9bbbd;
    }

    .ant-table-container table > .ant-table-thead > tr th:last-child {
      text-align: left;
    }
    
    .ant-table-container table > .ant-table-tbody .isDue {
      background: none !important;
      td.ant-table-cell-row-hover {
        background: none !important;
      }
      :hover {
        background: none !important;
      }
      :hover {
        background: none !important;
      }
      :nth-child(4) {
        color: #E04369;
      }
    }
  }

  .Task_Card {
    border-radius: 16px;

    .task_dashboard_header {
      background-color: #FBFBFC;
      margin: -24px -24px 24px -24px;
      padding: 24px;
      border-radius: 16px 16px 22px 22px;
    }

    .section_header {
      position: relative;
      z-index: 1;
      left: auto;
      top: auto;
    }

    .ant-tabs-top > .ant-tabs-nav::before {
      border: 0;
    }
  }
  .taskCardStatus {
    margin-top: 0 !important;
    .ant-card {
      border-radius: 10px;
      border: 0;
      // padding: 12px 16px 11px 16px;
      .ant-card-body {
        padding: 16px;
        .ant-card-meta {
          margin: 0;
        }
      }
    }
  }
  .taskCard_Col {
    // display: contents;
    padding-right: 12px;
  }
}

.dashboard_status_card {
  border-radius: 12px;

  .ant-card-meta-avatar {
    padding-right: 12px;
  }

  .ant-card-meta-title {
    font-size: 32px !important;
    color: #16181D;
    margin-bottom: 8px !important;
  }

  .ant-card-meta-description {
    font-size: 14px !important;
    line-height: 20px !important;
    color: #16181D;
    font-weight: 400 !important;
  }
  
  h1.ant-typography {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 26px;
  }
  .dashboard_status {
    .ant-divider-horizontal {
      margin: 10px 0 14px 0;
    }
    .subStatus {
      background: rgba(185, 187, 189, 0.14);
      border-radius: 6px;
      color: #7f8385;
      font-size: 12px;
      padding: 8px;
      margin: 8px -8px 4px -8px;
    }
    .ant-collapse {
      padding-bottom: 5px;
      > .ant-collapse-item {
        > .ant-collapse-header {
          padding: 0;
          display: flex;
          flex-direction: row-reverse;
          padding-right: 110px;
          justify-content: flex-end;

          .ant-badge-status-text {
            color: #1b1c1d;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
          }

          .ant-collapse-header-text {
            flex: inherit;
          }

          .ant-collapse-expand-icon {
            position: absolute;
            right: 0;
            top: 0;

            .anticon {
              svg {
                transform: rotate(-90deg);
              }
            }
          }

          .anticon {
            margin-left: 8px;
            margin-right: 0;
          }

          .ant-collapse-extra {
            position: absolute;
            top: 0;
            right: 0;
          }
        }
        .ant-collapse-content-box {
          padding-top: 6px;
          padding-bottom: 0;
        }
      }

      .ant-collapse-content-box {
        padding-right: 0;
        padding-top: 0;
      }

      .status_row {
        padding: 0;
        font-size: 11px;
        color: #7f8385;

        .status_num {
          color: #7f8385;
          font-size: 12px;
        }
      }
    }

    .ant-badge-status-dot {
      width: 8px;
      height: 8px;
      border-radius: 100%;
    }

    .status_right {
      display: flex;
      align-items: center;
      margin-left: auto;
      padding-right: 20px;

      .status_divider {
        color: #dbdddf;
        margin: 0 16px;
      }

      .status_num {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #1b1c1d;
        width: 45px;
        text-align: right;

        &:last-child {
          text-align: left;
          width: 35px;
        }
      }
    }

    .status_row {
      display: flex;
      padding: 4px 0 8px;
      font-size: 14px;
      color: #1b1c1d;
    }
  }
  .g2-tooltip-list-item {
    display: flex;
  }
  .g2-tooltip-name {
    font-size: 11px;
    white-space: nowrap;
  }
  .g2-tooltip-marker {
    margin-top: 3px;
  }
}

.pie_wrapper {
  width: 155px;
  height: 155px;
  margin: 0 auto;
}

.dashboard_progress {
  background-color: #16181D;
  border-radius: 12px;

  .ant-progress-circle {
    .ant-progress-text {
      width: 78%;
      height: 78%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 22px;
      font-weight: 500;
    }
  }

  .ant-card-body {
    padding: 19px 16px;
    display: flex;
    align-items: center;
  }

  .progress_text {
    padding-left: 16px;

    .rooms_text {
      color: #fff;
      font-weight: 400;
      font-size: 24px;
      line-height: 1;

      span {
        font-weight: 700;
        font-size: 32px;
      }
    }

    .occupancy_text {
      margin-top: 5px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #9CA0AA;
    }
  }
}

.dashboard_status_card {
  &.statuses_cards {
    > .ant-card-body {
      padding: 24px 24px;
    }
  }

  .ant-card {
    border-radius: 8px;
    width: 100%;
    border: 0;

    > .ant-card-body {
      padding: 12px 16px;
    }

    .ant-card-meta-avatar {
      padding-right: 12px;
      display: flex;
      align-items: center;

      > span {
        display: flex;
        align-items: center;
      }
    }
  }

  .stayovers {
    background-color: rgba(133, 120, 215, 0.14);

    .ant-card-meta-detail {
      .ant-card-meta-description,
      .ant-card-meta-title {
        color: #8578d7;
      }
    }
  }
  .arrivals {
    background-color: rgba(107, 184, 112, 0.14);
    .ant-card-meta-detail {
      .ant-card-meta-description,
      .ant-card-meta-title {
        color: #6bb870;
      }
    }
  }
  .departures {
    background-color: rgba(250, 141, 105, 0.14);
    .ant-card-meta-detail {
      .ant-card-meta-description,
      .ant-card-meta-title {
        color: #fa8d69;
      }
    }
  }
}

.dashboard_card {
  padding-top: 0;
  .ant-card {
    border: 1px solid #F2F3F7;
    height: 100%;
    overflow: inherit;
  }
}

.morning_meeting {
  .ant-card-body {
    height: 100%;
  }

  .ant-typography {
    font-weight: 500;
  }
}

.meeting_tabs {
  height: calc(100% - 18px);
  display: flex;
  flex-direction: column;
  padding-top: 5px;

  .meeting_textarea >div,
  .meeting_textarea,
  .meeting_wrapper,
  .ant-tabs-tabpane,
  .ant-tabs-content,
  .ant-tabs {
    height: 100%;
  }


  .meeting_wrapper.update_available .meeting_textarea {
    height: calc(100% - 25px);
  }

  .ant-tabs-editable-card {
    .ant-tabs-content-holder {
      margin-bottom: 8px;
      padding-bottom: 8px;
    }
  }

  .ant-tabs-nav:before,
  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs {
    > .ant-tabs-nav {
      max-width: 377px;

      .ant-tabs-nav-list {
        transform: none !important;
        overflow-y: auto;
        padding: 1px 0;
        min-width: 100%;
        //padding-left: 5px;

        .ant-tabs-nav-add {
          visibility: visible !important;
        }
      }
      .ant-tabs-nav-more {
        display: none;
      }
    }
  }

  .ant-tabs-card {
    &.ant-tabs-top {
      > .ant-tabs-nav {
        .ant-tabs-nav-list {
          overflow: auto;

          &::-webkit-scrollbar {
            height: 8px;
          }
        }
        .ant-tabs-nav-more {
          display: none;
        }
        .ant-tabs-tab {
          margin: 0;
          padding: 8px 12px;
          border: 0;
          border-top: 1px solid #7f8385;
          border-bottom: 1px solid #7f8385;
          border-radius: 0;
          background: transparent;

          &:first-child {
            border-top-left-radius: 44px;
            border-bottom-left-radius: 44px;
            border-left: 1px solid #7f8385;
          }

          &:nth-last-child(-n + 3) {
            border-top-right-radius: 44px;
            border-bottom-right-radius: 44px;
            border-right: 1px solid #7f8385;

            &.ant-tabs-tab-active {
              border-right: 0;
            }
          }

          // :last-of-type {
          //   border-radius: 0 6px 6px 0;
          //   border-right: 1px solid #7F8385;
          // }

          &.ant-tabs-tab-active {
            background-color: transparent;
            border-top: 1px solid #16181D;
            border-bottom: 1px solid #16181D;

            &:after {
              content: '';
              position: absolute;
              top: -1px;
              left: -1px;
              width: calc(100% + 2px);
              height: calc(100% + 2px);
              background-color: #16181D;
              border-radius: 44px;
            }

            .ant-tabs-tab-btn {
              color: #fff;
            }
          }

          .ant-tabs-tab-btn {
            color: #7f8385;
            font-weight: 400;
            font-size: 12px;
            line-height: 13px;
            position: relative;
            z-index: 2;
          }

          .ant-tabs-tab-remove {
            display: none;
          }
        }
        .ant-tabs-nav-add {
          border: 1 solid #E4E5E7;
          border-radius: 100%;
          min-width: 30px;
          width: 30px;
          height: 30px;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 7px;
          background-color: #fff;

          .anticon {
            svg {
              path {
                stroke: #16181D;
              }
            }
          }
        }
      }
    }
  }

  .meeting_textarea {
    width: 100%;

    .MuiInput-underline:after,
    .MuiInput-root:before {
      display: none;
    }

    textarea {
      height: 100% !important;
      color: #16181D;
    }
  }
}

.one_row {
  .cleaning_card {
    min-height: 293px;
    .cleaning_wrapper {
      display: flex;
      margin-left: -10px;
      margin-top: 18px;

      .dashboard_status {
        width: 100%;
      }
    }
    // .ant-card-body {
    //   display: flex;
    //   flex-wrap: wrap;

    //   .ant-typography {
    //     width: 100%;
    //   }
    // }
  }
}

// @media (max-width: 1599px) {
  .cleaning_wrapper {
    display: flex;
    margin-left: -10px;
    margin-top: 16px;

    .dashboard_status {
      width: 100%;
    }
  }
// }

.employees-shift-editform {
  .ant-form-item {
    margin-bottom: 16px;
  }
  .ant-picker-input > input {
    line-height: 2;
  }
  .edit-buttons {
    margin-top: 24px;
  }
  .shift-paragraph {
    .ant-typography {
      color: #1B1C1D;
      font-size: 14px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.003em;
      margin-bottom: 24px;
    }
  }
 .ant-form-item-control-input-content {
  .ant-picker-input {
    input {
      text-transform: none;
    }
  }
  input {
    text-transform: capitalize;
  }
 }
.ant-form-item-control-input-content .option-label-item  span:first-child {
  margin-right: 5px;
}

  .edit-shift_radio {
    // margin-top: 0;

    .ant-radio-group {
      max-width: 425px;
      display: flex;
      border-radius: 8px;
      flex-wrap: nowrap;
      flex-direction: row;

      .ant-radio-button-wrapper {
        // width: 50%;
        text-align: center;
        box-shadow: none !important;
        color: #7f8385;
        font-size: 14px;
        font-weight: 400;
        text-shadow: none !important;
        line-height: 37px;
        height: 39px;
        margin: 0;
        padding: 0;
        min-width: 55px;
        padding: 0 16px;

        &::before {
          display: none;
        }

        &:focus {
          box-shadow: none;
        }

        &:first-child {
          border: 1px solid #dbdddf;
          border-radius: 8px 0 0 8px;
          border-right: 0;
        }

        &:last-child {
          border: 1px solid #dbdddf;
          border-radius: 0 8px 8px 0;
          border-left: 0;
        }

        &.ant-radio-button-wrapper-checked {
          background-color: $green;
          color: #fff;
          border: 1px solid $green;

          &:first-child {
            border-right: 0;
          }

          &:last-child {
            border-left: 0;
          }
        }
      }
    }
  }
}
.mb-27 {
  margin-bottom: 27px;
}
.mb-14 {
  margin-bottom: 14px;
}

.shift-paragraph {
  .ant-typography {
    color: #1B1C1D;
    font-size: 14px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.003em;
    margin-bottom: 24px;
  }
}
.empty-shift {
  // display: block;
  // align-items: center;
  text-align: center;
  // margin-left: auto;
  margin: auto;
  // padding: 50% 0;

  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 115px);


  .empty-text {
    color: #7f8385;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.003em;
    margin-top: 8px;
  }
  .create-shift-link {
    color: #6bb870;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.006em;
    margin-top: 27px;
    padding: 0;
    background: none;
    border: none;
    margin-bottom: 40px;
  }
  [ant-click-animating-without-extra-node='true']::after,
  .ant-click-animating-node {
    --antd-wave-shadow-color: none;
  }
}

.create-shift {
  width: 100%;
  background-color: #f5f5f5;
  min-height: 100vh;
  padding-top: 64px;

  .shift-card {
    padding: 16px;

    .ant-card-bordered {
      border: 0;

      .ant-card-body {
        padding: 24px 36px;
      }
    }
    .shift-form {
      width: 550px;
      .custom_shift_dropdown {
        color: #1b1c1d;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        .ant-select-selector {
          border: none;
          padding-left: 0 !important;
        }
        .ant-select-selection-item {
          font-weight: 500;
          font-size: 16px;
        }
        .ant-select-arrow {
          // right: 28px;
          right: 15px;
        }
        .option-label-item {
          line-height: 33px;
        }
      }
      .ant-form-item-explain-error {
        margin-bottom: 12px;
      }
      .ant-btn.ant-btn-text {
        margin-left: 185px;
        margin-bottom: 16px;
      }
      .filter_dropdown {
        width: 200px;
        margin-bottom: 20px;
        
        .ant-select-selector {
          // width: 160px;
          width: 200px;
          background: transparent;
          padding-right: 0;
          
          .ant-select-selection-search {
            input {
              cursor: pointer !important;
            }
          }
        }
      }

      .create-buttons {
        display: contents;
        .row-bttn {
          margin-left: 3px !important;
          margin-left: -185px !important;
          .m8 {
            margin-right: 8px;
          }
        }
      }
      .edit-shift_radio {
        // margin-top: 0;

        .ant-radio-group {
          max-width: 425px;
          display: flex;
          border-radius: 8px;
          flex-wrap: nowrap;
          flex-direction: row;

          .ant-radio-button-wrapper {
            // width: 50%;
            text-align: center;
            box-shadow: none !important;
            color: #7f8385;
            font-size: 14px;
            font-weight: 400;
            text-shadow: none !important;
            line-height: 37px;
            height: 39px;
            margin: 0;
            padding: 0;
            min-width: 55px;
            border-left: 0;
            border-right: 0;
            padding: 0 16px;

            &::before {
              display: none;
            }

            &:focus {
              box-shadow: none;
            }

            span {
              position: relative;
              z-index: 2;
            }

            &:first-child {
              border: 1px solid #dbdddf;
              border-radius: 6px 0 0 6px;
              // border-radius: 8px;
              border-right: 0;
              // margin-right: -8px;
              // padding-right: 8px;
            }

            &:last-child {
              border: 1px solid #dbdddf;
              border-radius: 0 6px 6px 0;
              // border-radius: 8px;
              border-left: 0;
            }

            &.ant-radio-button-wrapper-checked {
              color: #fff;
              border-radius: 6px;
              background-color: transparent;
              border-top: 1px solid #16181D;
              border-bottom: 1px solid #16181D;

              &:after {
                content: '';
                position: absolute;
                top: -1px;
                left: -1px;
                width: calc(100% + 2px);
                height: calc(100% + 2px);
                background-color: #16181D;
                border-radius: 6px;
              }
            }
          }
        }
      }
      
      .time_heading {
        .ant-row {
          .ant-form-item-label {
            // padding-bottom: 20px;
            padding-bottom: 11.5px;
          }
        }
        .ant-form-item-explain-error {
          margin-bottom: 30px;
        }
        .ant-picker-input {
          input {
            margin-bottom: 9px;
          }
        }
        .ant-picker-suffix {
          display: none;
        }
      }

      

      // .ant-row {
      //   &:first-child {
      //     .custom_shift_dropdown {    
      //       .ant-select-selection-item {
      //         &:before {
      //           content: 'Select Role';
      //           color: #1B1C1D;
      //           font-weight: 500;
      //           font-size: 16px;
      //           line-height: 19px;
      //           position: absolute;
      //           top: 6px;
      //           left: 0;
      //         }
      //         > .option-label-item {
      //           background-color: #fff;
      //           position: relative;
      //           z-index: 1;
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }
}

.add-role-btn {
  &.ant-btn {
    line-height: 16px;
    color: #6bb870;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    background: none;
    // padding: 0 0 40px 0;
    margin-bottom: 40px;
    padding:9px 10px 9px 0;

    > .anticon {
      font-size: 14px !important;

      + span {
        font-size: 13px !important;
      }
    }
  }
}


.ant-table {
  &.ant-table-empty {
    .ant-table-container {
      .ant-table-tbody > tr {
        cursor: inherit !important;
        &:hover {
          background-color: transparent !important;
        }
        > td {
          border-bottom: none;
          transition: none;
          color: #7f8385;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
}

.sidebar_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;

  .dashboard_circle_btn {
    width: 32px !important;
    height: 32px !important;
    min-width: 32px;
  }
}



.ant-dropdown-menu .ant-dropdown-menu-item .ant-btn.shift_delete {
  color: #f37066 !important;
  :hover {
    color: #f37066 !important;
  }
}

.employees-shift-editform {
  .edit-shift_radio {
    // margin-top: 0;

    .ant-radio-group {
      max-width: 425px;
      display: flex;
      border-radius: 8px;
      flex-wrap: nowrap;
      flex-direction: row;

      .ant-radio-button-wrapper {
        // width: 50%;
        text-align: center;
        box-shadow: none !important;
        color: #7f8385;
        font-size: 14px;
        font-weight: 400;
        text-shadow: none !important;
        line-height: 37px;
        height: 39px;
        margin: 0;
        padding: 0;
        min-width: 55px;
        padding: 0 16px;
        border-left: 0;
        border-right: 0;

        &::before {
          display: none;
        }

        &:focus {
          box-shadow: none;
        }

        span {
          position: relative;
          z-index: 2;
        }

        &:first-child {
          border: 1px solid #dbdddf;
          border-radius: 6px 0 0 6px;
          // border-radius: 8px;
          border-right: 0;
        }

        &:last-child {
          border: 1px solid #dbdddf;
          border-radius: 0 6px 6px 0;
          // border-radius: 8px;
          border-left: 0;
        }

        &.ant-radio-button-wrapper-checked {
          background-color: $green;
          color: #fff;
          border: 1px solid $green;
          border-radius: 8px;
          &:after {
            content: '';
            position: absolute;
            top: -1px;
            left: -1px;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            background-color: #6bb870;
            border-radius: 6px;
          }
        }
      }
    }
  }

  .employee_select {
    .ant-select-multiple {
      .ant-select-selection-item {
        padding-left: 4px;
        height: 28px;
      }
    }
    .option-label-item {
      padding: 1px;

      span {
        &:first-child {
          margin-right: 0;
        }
      }
    }
  }

  .ant-form-item 
  .ant-input-disabled {
    color: #B9BBBD;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }
}

.conflict-modal {
  .ant-typography {
    margin: 0;
  }

  .conflict_person {
    background: #F5F5F5;
    border-radius: 8px;
    border: 0;

    .ant-card-body {
      padding: 12px 8px;
    }
    .ant-card-meta-avatar {
      padding-right: 8px;
    }

    .ant-card-meta-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #16181D;
      margin-bottom: 2px;
    }
    .ant-card-meta-description {
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      color: #686D79;
    }
  }
}

.info_card {
  max-width: 550px;
  background: rgba(107, 184, 112, 0.14);
  border-radius: 8px;
  margin-bottom: 16px;

  &.error_card_bg {
    background: #FDEFF3;
  }

  .ant-card-body {
    padding: 16px 26px 18px 12px !important;
  }

  .info_text {
    .ant-typography {
      color: #53595C;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;

      b {
        font-weight: 500;
        font-size: 14px;
        color: #53595C;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
    
  }
}

.sidebar_menu_workshift {
  z-index: 1000;
}

.conflictMessage {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #F37066;
  padding: 0 10px;
}

.side_btns {
  display: flex;
  margin-left: auto;
}

.combine_image {
  position: relative;

  .self_image {
    position: absolute;
    bottom: -4px;
    right: -4px;
    width: 22px;
    height: 22px;
  }
}

.non_schedule_tag {
  color: #16181D;
  font-size: 10px;
  line-height: 16px;
  display: flex;
  margin-left: 2px;
  width: 85px;
  height: 17px;
  border: 1px solid #9CA0AA;
  border-radius: 24px;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 0 2px;
}

.custom_timepicker {
  .ant-picker-content {
    .ant-picker-time-panel-column {
      &:nth-child(3n) {
        display: none;
      }
    }
  }
}

.synchronise-info {
  width: 18px;
    height: 18px;
    line-height: 0;
    margin-left: 8px;

  .anticon-info-circle {
    color: #A3A6A9;
    width: 18px;
    height: 18px;
    
    svg {
      width: 18px;
    height: 18px;
    }
  }
}


.dashboard_left_content {
  width: calc(100% - 344px);
  height: 100%;
  overflow: auto;
}

.dashboard_tabs {
  width: calc(100% - 155px);
  padding-left: 26px;
}

.morning_meeting_dashboard {
  min-height: 380px;
  
  .ant-spin-container,
  .ant-spin-nested-loading {
    height: 100%;
  }
}

.home_modal {
  button {
    &.ant-btn {
      padding: 10px 20px;
      border-radius: 44px;
      width: 100%;

      &.grey-btn{
        border: 1px solid #E4E5E7 !important;
        background-color: transparent;
        color: #16181D;
      }

      &.close_popup {
        padding: 5px 20px 0;
      }
    }
  }
}